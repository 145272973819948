import Vue from 'vue'
import App from './App.vue'
import store from './store'

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
Vue.use(VueTelInput, {placeholder: 'XXXXXXXXX'});

import UUID from "vue-uuid";
Vue.use(UUID);

import VueI18n from 'vue-i18n'
Vue.use(VueI18n);

import { languages } from './translations/index.js';
import { defaultLocale } from './translations/index.js';
const messages = Object.assign(languages);

Vue.config.productionTip = false;

export const changeDay = new Vue();
let i18n = new VueI18n({
    locale: defaultLocale,
    fallbackLocale: 'es',
    messages
});

new Vue({
  store,
  i18n,
  render: function (h) { return h(App) }
}).$mount('#aion-checkout-widget');
